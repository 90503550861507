let projectID = process.env.REACT_APP_INFURA_PROJECT_ID,
  chainTokenMaticAddress = '',
  rewardAddress = '',
  unstakingPeriod = 60 * 60 * 24,
  deepLink = { COINBASE: '' },
  chainNetwork = {
    5: {
      NETWORK: 'ETHEREUM TESTNET',
      TOKEN: 'ETH',
      EXPLORER: 'Etherscan',
      STAKING_ACC_EXP_URL:
        'https://goerli.etherscan.io/address/0xe4E7B46A9387ef145b2Aa2455D7D59e01FA232C8',
    },
    80001: {
      NETWORK: 'POLYGON TESTNET',
      TOKEN: 'MATIC',
      EXPLORER: 'Polygonscan',
      STAKING_ACC_EXP_URL:
        'https://mumbai.polygonscan.com/address/0xd3a4eF9c7Ec0b30150f8D9d614104F2745aABB3e',
    },
  },
  graphQLAPI = {
    5: 'https://api.studio.thegraph.com/query/48305/staking-chaingames-goerli/version/latest',
    80001: 'https://api.studio.thegraph.com/query/48305/staking-chaingames-mumbai/version/latest',
  },
  supportedNetworks = {
    ethereum: {
      name: 'Goerli Ethereum',
      chainID: 5,
      rpcURL: `https://goerli.infura.io/v3/${projectID}`,
      explorer: 'https://goerli.etherscan.io/tx/',
      ChainTokenAddress: '0x76EBA9F21d659Fc15D5f33a1a0C7f9088D0FBd28',
      StakingAddress: '0xA9F21E25C1fB0C55249942252e328Ee5eE2966AD',
    },
    matic: {
      name: 'Mumbai Matic',
      chainID: 80001,
      rpcURL: 'https://rpc-mumbai.maticvigil.com',
      explorer: 'https://mumbai.polygonscan.com/',
      ChainTokenAddress: '0x783288fb03079238dd917794ec16F812eB25B390',
      StakingAddress: '0xd3a4eF9c7Ec0b30150f8D9d614104F2745aABB3e',
    },
  };

const ENV = process.env.REACT_APP_ETH_PROVIDER;
switch (ENV) {
  case 'mainnet':
    unstakingPeriod = 60 * 60 * 24 * 7;
    supportedNetworks = {
      ethereum: {
        name: 'Ethereum Mainnet',
        chainID: 1,
        rpcURL: `https://mainnet.infura.io/v3/${projectID}`,
        explorer: 'https://etherscan.io/tx/',
        ChainTokenAddress: '0xC4C2614E694cF534D407Ee49F8E44D125E4681c4',
        StakingAddress: '0x9b7fcaebe9a69eceeab72142ed35a238775d179a',
      },
      matic: {
        name: 'Polygon Mainnet',
        chainID: 137,
        rpcURL: 'https://rpc-mainnet.matic.network',
        explorer: 'https://polygonscan.com/tx/',
        ChainTokenAddress: '0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509',
        StakingAddress: '0x1B52A94bB0A05a03Fc38ab4565B1F3A176833074',
      },
    };
    rewardAddress = '0x6B78Ff6668D639DEb89363d8E24f2abe2D7Ab1bA';
    deepLink = { COINBASE: 'https://go.cb-w.com/gBDa9b1Gt9' };
    graphQLAPI = {
      1: 'https://api.studio.thegraph.com/query/50377/chaingamestaking/version/latest',
      137: 'https://api.studio.thegraph.com/query/50377/polygon-chaingamestaking/version/latest',
    };
    chainNetwork = {
      1: {
        NETWORK: 'ETHEREUM MAINNET',
        TOKEN: 'ETH',
        EXPLORER: 'Etherscan',
        STAKING_ACC_EXP_URL:
          'https://etherscan.io/address/0x9b7fcaebe9a69eceeab72142ed35a238775d179a',
      },
      137: {
        NETWORK: 'POLYGON MAINNET',
        TOKEN: 'MATIC',
        EXPLORER: 'Polygonscan',
        STAKING_ACC_EXP_URL:
          'https://polygonscan.com/address/d3a4eF9c7Ec0b30150f8D9d614104F2745aABB3e',
      },
    };
    break;
  case 'testnet':
    supportedNetworks = {
      ethereum: {
        name: 'Goerli Ethereum',
        chainID: 5,
        rpcURL: `https://goerli.infura.io/v3/${projectID}`,
        explorer: 'https://goerli.etherscan.io/tx/',
        ChainTokenAddress: '0x76EBA9F21d659Fc15D5f33a1a0C7f9088D0FBd28',
        StakingAddress: '0xe4E7B46A9387ef145b2Aa2455D7D59e01FA232C8',
      },
      matic: {
        name: 'Mumbai Matic',
        chainID: 80001,
        rpcURL: 'https://rpc-mumbai.maticvigil.com',
        explorer: 'https://mumbai.polygonscan.com/tx/',
        ChainTokenAddress: '0x783288fb03079238dd917794ec16F812eB25B390',
        StakingAddress: '0xd3a4eF9c7Ec0b30150f8D9d614104F2745aABB3e',
      },
    };
    rewardAddress = '0x3B4F2B2Ba28c5CF22DD7B1665742F2DE658B0b53';
    deepLink = {
      COINBASE: 'https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fstaking.testnet.chaingames.io%2F',
    };
    chainNetwork = {
      5: {
        NETWORK: 'ETHEREUM TESTNET',
        TOKEN: 'ETH',
        EXPLORER: 'Etherscan',
        STAKING_ACC_EXP_URL:
          'https://goerli.etherscan.io/address/0xe4E7B46A9387ef145b2Aa2455D7D59e01FA232C8',
      },
      80001: {
        NETWORK: 'POLYGON TESTNET',
        TOKEN: 'MATIC',
        EXPLORER: 'Polygonscan',
        STAKING_ACC_EXP_URL:
          'https://mumbai.polygonscan.com/address/0xd3a4eF9c7Ec0b30150f8D9d614104F2745aABB3e',
      },
    };
    graphQLAPI = {
      5: 'https://api.studio.thegraph.com/query/48305/staking-chaingames-goerli/version/latest',
      80001: 'https://api.studio.thegraph.com/query/48305/staking-chaingames-mumbai/version/latest',
    };
    break;
  default:
    throw new Error(`REACT_APP_ETH_PROVIDER must be a defined environment variable`);
}

export const SupportedNetworks = supportedNetworks;
export const MaticRpcURL = supportedNetworks.matic.rpcURL;
export const ChainTokenMaticAddress = chainTokenMaticAddress;
export const RewardAccount = rewardAddress;
export const NetworkURL = supportedNetworks.ethereum.rpcURL;
export const UnstakingPeriod = unstakingPeriod;
export const DEEPLINK = deepLink;
export const CHAIN_NETWORK = chainNetwork;
export const GraphQLAPI = graphQLAPI;
export const POOL_MAX_CAP = 150000000;
