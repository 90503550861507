import { gql } from '@apollo/client';

export const GET_STAKERS_DEPOSIT_TX = gql`
  query StakeDepositedsTx {
    stakeDepositeds(orderBy: amount, orderDirection: desc, first: 1000) {
      amount
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_REWARD_WITHDRAW_TX_BY_ADDRESS = gql`
  query RewardsWithdrawnsTxByAddress($address: Bytes!) {
    rewardsWithdrawns(where: { account: $address }) {
      reward
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_REWARD_WITHDRAW_TX_BY_ADDRESS_DESC = gql`
  query RewardsWithdrawnsByAddDesc($noOfTx: Int!, $address: Bytes!) {
    rewardsWithdrawns(
      orderBy: blockTimestamp
      orderDirection: desc
      first: $noOfTx
      where: { account: $address }
    ) {
      reward
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_STAKER_DEPOSIT_TX_BY_ADDRESS_DESC_TIMESTAMP = gql`
  query StakeDepositedsTxByDescTimestamp($noOfTx: Int!, $address: Bytes!) {
    stakeDepositeds(
      orderBy: blockTimestamp
      orderDirection: desc
      first: $noOfTx
      where: { account: $address }
    ) {
      amount
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_WITHDRAW_INITIATED_TX_BY_ADDRESS_DESC_TIMESTAMP = gql`
  query WithdrawInitiatedsTxByAddDescTimestamp($noOfTx: Int!, $address: Bytes!) {
    withdrawInitiateds(
      first: $noOfTx
      orderBy: blockTimestamp
      orderDirection: desc
      where: { account: $address }
    ) {
      amount
      initiateDate
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_WITHDRAW_EXECUTED_TX_BY_ADDRESS_DESC_TIMESTAMP = gql`
  query WithdrawExecutedsTxByAddDescTimestamp($noOfTx: Int!, $address: Bytes!) {
    withdrawExecuteds(
      first: $noOfTx
      orderBy: blockTimestamp
      orderDirection: desc
      where: { account: $address }
    ) {
      amount
      reward
      id
      account
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const GET_LATEST_REWARD_DISTRIBUTED_BLOCK_INFO = gql`
  query getLatestRewardDistributedBlockInfo {
    rewardsDistributeds(orderBy: blockTimestamp, orderDirection: desc, first: 1) {
      id
      blockNumber
      blockTimestamp
    }
  }
`;

export const GET_ACCOUNT_IN_COOLING_PERIOD = gql`
  query getAccountInCoolingPeriod($currentTimestamp: Int!, $before7dayTimestamp: Int!) {
    withdrawInitiateds(
      where: { initiateDate_lte: $currentTimestamp, initiateDate_gt: $before7dayTimestamp }
    ) {
      account
    }
  }
`;

export const GET_WITHDRAW_EXECUTED_AMOUNT = gql`
  query getLatestRewardDistributedBlockInfo {
    withdrawExecuteds {
      reward
      account
      amount
    }
  }
`;
