import React, { useState, useEffect, useCallback } from 'react';
import { useStakingContract } from '../../context/StakingContract';
import { walletConnectV2, coinbaseWallet, metamask } from '../connectors/connectors';
import { useWeb3React } from '@web3-react/core';
import { isMobile } from 'react-device-detect';
import StakingContract from '../../context/StakingContract/StakingContract';
import { convertLongStrToShort } from '../../utils/helpers';
import useConnectedWallet from '../../hooks/useConnectedWallet';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import wait from '../../utils/wait';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { DEEPLINK } from '../../config/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  img: {
    cursor: 'pointer',
    width: 28,
    height: 28,
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.dark,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    textTransform: 'none',
    paddingBottom: 10,
    paddingTop: 10,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
  },
  activeWallet: {
    borderColor: theme.palette.secondary.main,
    borderRadius: 4,
  },
}));

export const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: metamask,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletConnectV2,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: coinbaseWallet,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    connector: null,
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: DEEPLINK.COINBASE,
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },
};

export default function WalletSelector() {
  const { stakingContract } = useStakingContract();
  const { account, provider, connector, isActive } = useWeb3React();
  const [connectedWallet, setConnectedWallet] = useConnectedWallet();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleClickOpen = () => {
    SUPPORTED_WALLETS.WALLET_CONNECT.connector.walletConnectProvider = undefined;
    if (isMobile && StakingContract.hasMetamask()) {
      if (connectedWallet !== SUPPORTED_WALLETS.WALLET_CONNECT) {
        tryActivation(metamask, true);
      }
    } else {
      setOpen(true);
    }
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    if (stakingContract) {
      stakingContract.setWeb3ReactProvider(provider);
    }
  }, [stakingContract, provider]);

  const tryActivation = useCallback(
    async (connector, skipSetConnectedWallet = false) => {
      if (!skipSetConnectedWallet) {
        Object.keys(SUPPORTED_WALLETS).map(key => {
          if (connector === SUPPORTED_WALLETS[key].connector) {
            setConnectedWallet(key);
            return false;
          }
          return true;
        });
      }

      await wait(1000); // ugly! Change it

      await connector
        .activate()
        .then(res => {
          setOpen(false);
        })
        .catch(async error => {
          console.log(`error: unsupported`, error);
        });
    },
    [setConnectedWallet]
  );

  useEffect(() => {
    if (connectedWallet) {
      if (connectedWallet === 'WALLET_CONNECT') {
        walletConnectV2.connectEagerly().catch(error => {
          console.error('Persistent connection error at wallet connect: ', error);
        });
      } else if (connectedWallet === 'METAMASK') {
        metamask.connectEagerly().catch(error => {
          console.error('Persistent connection error at metamask: ', error);
        });
      } else if (connectedWallet === 'WALLET_LINK' || connectedWallet === 'COINBASE_LINK') {
        coinbaseWallet.connectEagerly().catch(error => {
          console.error('Persistent connection error at coinbaseWallet: ', error);
        });
      }
    }
  }, [connectedWallet]);

  useEffect(() => {
    if (connectedWallet !== 'WALLET_CONNECT') {
      if (isMobile && StakingContract.hasMetamask()) {
        metamask.connectEagerly().catch(error => {
          console.error('Persistent connection error at metamask: ', error);
        });
      }
    }
  }, [connectedWallet]);

  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        if (!window.ethereum && option.mobile) {
          return key;
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === metamask) {
        // don't show injected if there's no injected provider
        if (!window.ethereum) {
          return null;
        }

        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null;
        }
      }

      // return rest of options
      if (!isMobile && !option.mobileOnly) {
        return key;
      } else {
        return null;
      }
    });
  }

  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = value => {
      tryActivation(SUPPORTED_WALLETS[value].connector);
      onClose(value);
    };

    const onDisconnect = () => {
      try {
        if (connector?.deactivate) {
          connector.deactivate();
          setConnectedWallet('');
        } else {
          connector.resetState();
          setConnectedWallet('');
        }
        handleClose(selectedValue);
      } catch (error) {
        console.error('error occured during deactivate wallet: ', error);
      }
    };

    return (
      <Dialog
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle className={classes.dialogTitle} id="simple-dialog-title">
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="textPrimary">
              Connect to a wallet
            </Typography>

            <Box flexGrow={1} />
            <Box>
              <IconButton id="connect-wallet" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {getOptions().map(
            connectorObject =>
              connectorObject && (
                <Box
                  mb={1}
                  key={connectorObject}
                  className={classes.activeWallet}
                  border={
                    isActive && SUPPORTED_WALLETS[connectorObject].connector === connector ? 2 : 0
                  }
                >
                  <Button
                    id={connectorObject}
                    className={classes.button}
                    onClick={() => handleListItemClick(connectorObject)}
                    key={connectorObject}
                    fullWidth
                    display="flex"
                    variant="outlined"
                    href={SUPPORTED_WALLETS[connectorObject].href}
                  >
                    <Typography variant="body1" color="textPrimary">
                      {SUPPORTED_WALLETS[connectorObject].name}
                    </Typography>
                    <Box flexGrow={1} />
                    <img
                      className={classes.img}
                      src={
                        '/static/images/walletIcons/' + SUPPORTED_WALLETS[connectorObject].iconName
                      }
                    />
                  </Button>
                </Box>
              )
          )}
          {provider && (
            <Button variant="outlined" color="secondary" onClick={onDisconnect}>
              Disconnect
            </Button>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  return (
    <>
      <Button
        id="connectToWallet"
        color="secondary"
        variant="contained"
        size="small"
        onClick={handleClickOpen}
      >
        {account ? convertLongStrToShort(account, 6, 4) : 'Connect to a Wallet'}
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </>
  );
}
