import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from '../../../components/Logo';
import { THEMES } from '../../../constants';
import Settings from './Settings';
import { useDispatch } from '../../../store';
import { login, logout } from '../../../slices/account';
import { useWeb3React } from '@web3-react/core';
import WalletSelector from '../../../components/WalletSelector/WalletSelector';
import { CHAIN_NETWORK } from '../../../config/constants';
import { useStakingContract } from '../../../context/StakingContract/index';
import { useQuery } from '@apollo/client';
import { GET_REWARD_WITHDRAW_TX_BY_ADDRESS } from '../../../graphql/Queries';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.background.paper,
        }
      : {}),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    ...(theme.name === THEMES.LIGHT
      ? {
          color: theme.palette.background.paper,
        }
      : {}),
  },
}));

const fetchTotalWithdrawnAmount = withdrawDetails => {
  let totalWithdrawedAmount;
  if (withdrawDetails?.length > 0) {
    totalWithdrawedAmount = withdrawDetails?.reduce((totalWithdrawAmount, withdraw) => {
      return totalWithdrawAmount + withdraw.reward;
    }, 0);
  } else {
    totalWithdrawedAmount = 0;
  }

  return totalWithdrawedAmount;
};

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { account, chainId } = useWeb3React();
  const { stakingContract } = useStakingContract();
  const { data, error } = useQuery(GET_REWARD_WITHDRAW_TX_BY_ADDRESS, {
    variables: {
      address: account,
    },
  });

  if (error) {
    console.error('error encountered during fetching rewardWithdrawTx data Topbar:', error);
  }

  useEffect(() => {
    if (account && stakingContract) {
      let withdrawedAmount = fetchTotalWithdrawnAmount(data?.rewardsWithdrawns);
      stakingContract.getStakeDeposit(account).then(stakeDetails => {
        const { initialDeposit, rewards } = stakeDetails;
        const accumulatedRewards = (withdrawedAmount + rewards) / 10 ** 18;
        if (account) {
          dispatch(
            login(account, initialDeposit / 10 ** 18, rewards / 10 ** 18, accumulatedRewards)
            );
        } else {
          dispatch(logout());
        }
      });
    } else {
      dispatch(logout());
    }
  }, [account, chainId, dispatch, stakingContract, data]);

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton id="logo" onClick={onMobileNavOpen}>
            <SvgIcon className={classes.icon} fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Button variant="outlined" size="small" color="secondary">
            {account && !!CHAIN_NETWORK[chainId]
              ? CHAIN_NETWORK[chainId]?.NETWORK
              : 'Wrong Network'}
          </Button>
        </Box>
        <Settings />
        <Divider className={classes.divider} />
        <Box ml={2}>
          <WalletSelector />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
