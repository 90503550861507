import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { SettingsProvider } from './contexts/SettingsContext';
import App from './App';
import { Web3ReactProvider } from '@web3-react/core';
import {
  walletConnectV2Hooks,
  walletConnectV2,
  metamask,
  metamaskHooks,
  coinbaseWallet,
  coinbaseWalletHooks,
} from './components/connectors/connectors';

enableES5();

const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metamask, metamaskHooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <Web3ReactProvider connectors={connectors}>
        <App />
      </Web3ReactProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
