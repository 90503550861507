import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { NetworkURL, SupportedNetworks } from '../../config/constants';
import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';

const NETWORK_URL = NetworkURL;

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`);
}

const rpcMap = {
  [SupportedNetworks.ethereum.chainID]: SupportedNetworks.ethereum.rpcURL,
  [SupportedNetworks.matic.chainID]: SupportedNetworks.matic.rpcURL,
};

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  actions =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        showQrModal: true,
        chains: [SupportedNetworks.ethereum.chainID],
        optionalChains: [SupportedNetworks.matic.chainID],
        rpcMap,
        qrModalOptions: { themeMode: 'dark' },
      },
      onError: error => {
        console.error('WalletConnect Error:', error);
      },
    })
);

export const [metamask, metamaskHooks] = initializeConnector(
  actions =>
    new MetaMask({
      actions,
      onError: error => {
        console.error('Metamask Error:', error);
      },
    })
);

export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        url: NetworkURL,
        appName: 'ChainGames Staking',
        appLogoUrl: 'https://staking.chaingames.io/static/chaingames.png',
        darkMode: true,
        reloadOnDisconnect: false,
      },
      onError: error => {
        console.error('CoinbaseWallet Error:', error);
      },
    })
);
